export interface IEstimatingRequest {
  id: string,
  status: string,
  name: string,
  owner: string,
  dateCreated: string,
  datePending: string,
  dateReady: string
}

export interface ITemplate {
  id: string,
  name: string,
  type: string,
  dateCreated: string,
}

export interface ICompany {
  id: string,
  name: string,
  companyGroups: string,
  resultsTemplate: string,
  materialsTemplate: string,
  dateCreated: string,
}

export interface IUser {
  id: string,
  name: string,
  email: string,
  company: string,
  group: string,
  accessLevel: string,
}

export interface IUserWithPassword extends IUser {
  password: string
}

export interface IApiClient {
  login(username: string, password:string): void;
  logout(): void;
  getMe(): Promise<IUser>;

  getUsers(): Promise<IUser[]>;
  getUser(id: string): Promise<IUser>;
  createNewUser(user: IUser): Promise<IUser>;
  updateUser(user: IUser): Promise<IUser>;
  deleteUser(id: string): Promise<number>;

  getRequests(): Promise<IEstimatingRequest[]>;
  getRequest(id: string): Promise<IEstimatingRequest>;
  createNewRequest(file:File): Promise<IEstimatingRequest>;
  deleteRequest(id: string): Promise<string>;

  getTemplates(): Promise<ITemplate[]>;
  getTemplate(id: string): Promise<ITemplate>;
  createNewTemplate(file:File): Promise<ITemplate>;
  updateTemplate(id: string, template: ITemplate): Promise<ITemplate>;
  deleteTemplate(id: string): Promise<string>;

  getCompanies(): Promise<ICompany[]>;
  getCompany(id: string): Promise<ICompany>;
  createNewCompany(company: ICompany): Promise<ICompany>;
  updateCompany(id: string, company: ICompany): Promise<ICompany>;
  deleteCompany(id: string): Promise<string>;
}

let apiRoot = process.env.REACT_APP_APIROOT || "";
export class ApiClient implements IApiClient {

  async handleErrorMessages(response:Response) {
    if ((response.redirected && response.url.indexOf('/login')) ||
      response.headers.get("Location")?.indexOf('/login'))
    {
      window.location.href = "/?ReturnUrl=" + encodeURIComponent(window.location.pathname);
      throw new Error("Session expired. Please login!");
    }
    if (response.status === 400)
    {
      const message = await response.text();
      throw new Error(message);
    }

    throw new Error(`Received an unknown error from server: ${response.statusText}`);
  }

  async get<T>(path:string):Promise<T> {
    const response = await fetch(`${apiRoot}${path}`, {
      method: 'GET',
      credentials: 'include'
    });

    if (response.ok) {
      return await response.json() as T;
    }

    throw await this.handleErrorMessages(response);
  }

  async postFile<T>(path:string, file:File): Promise<T> {
    const formData = new FormData();
    formData.append('data', file);

    const response = await fetch(`${apiRoot}${path}`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      body: formData,
      credentials: 'include',
    });

    if (response.ok) {
      return await response.json() as T;
    }

    throw await this.handleErrorMessages(response);
  }

  async update<T>(path:string, method:string, data:T):Promise<T> {
    const response = await fetch(`${apiRoot}${path}`, {
      method: method,
      credentials: 'include',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      },
    });

    if (response.ok) {
      return await response.json() as T;
    }

    throw await this.handleErrorMessages(response);
  }

  async delete<T>(path:string):Promise<T> {
    const response = await fetch(`${apiRoot}${path}`, {
      method: 'DELETE',
      credentials: 'include'
    });

    if (response.ok) {
      return await response.json() as T;
    }

    throw await this.handleErrorMessages(response);
  }

  async login(username: string, password:string): Promise<IUser> {
    const response = await fetch(`${apiRoot}/api/login`, {
      method: 'POST',
      redirect: 'follow',
      body: JSON.stringify({username:username, password:password}),
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    });

    if (response.ok) {
      return await response.json() as IUser;
    }
    else if (response.status === 401) {
      throw new Error('Incorrect username or password!')
    }

    throw await this.handleErrorMessages(response);
  }

  async logout() {
    return await fetch(`${apiRoot}/api/logout`, {
      method: 'POST',
      redirect: 'follow',
      credentials: 'include'
    });
  }

  async getMe(): Promise<IUser> {
    return await this.get(`/api/me`);
  }

  async getUsers(): Promise<IUser[]> {
    return await this.get(`/api/users`);
  }
  async getUser(id: string): Promise<IUser> {
    return await this.get(`/api/users/${encodeURIComponent(id)}`);
  }
  async createNewUser(user: IUser): Promise<IUser> {
    return await this.update(`/api/users`, 'post', user);
  }
  async updateUser(user: IUser): Promise<IUser> {
    return await this.update(`/api/users/${encodeURIComponent(user.id)}`, 'PATCH', user);
  }
  async deleteUser(id: string): Promise<number> {
    return this.delete(`/api/users/${encodeURIComponent(id)}`);
  }

  async getRequests(): Promise<IEstimatingRequest[]> {
    return await this.get(`/api/requests`);
  }
  async getRequest(id: string): Promise<IEstimatingRequest> {
    return await this.get(`/api/requests/${encodeURIComponent(id)}`);
  }
  async createNewRequest(file:File): Promise<IEstimatingRequest> {
    return this.postFile(`/api/requests/upload`, file);
  }
  async updateRequest(id: string, request: IEstimatingRequest): Promise<IEstimatingRequest> {
    return await this.update(`/api/requests/${encodeURIComponent(id)}`, 'PATCH', request);
  }
  async deleteRequest(id: string): Promise<string> {
    return this.delete(`/api/requests/${encodeURIComponent(id)}`);
  }

  async getTemplates(): Promise<ITemplate[]> {
    return await this.get(`/api/templates`);
  }
  async getTemplate(id: string): Promise<ITemplate> {
    return await this.get(`/api/templates/${encodeURIComponent(id)}`);
  }
  async createNewTemplate(file: File): Promise<ITemplate> {
    return this.postFile(`/api/templates/upload`, file);
  }
  async updateTemplate(id: string, template: ITemplate): Promise<ITemplate> {
    return await this.update(`/api/templates/${encodeURIComponent(id)}`, 'PATCH', template);
  }
  async deleteTemplate(id: string): Promise<string> {
    return this.delete(`/api/templates/${encodeURIComponent(id)}`);
  }

  async getCompanies(): Promise<ICompany[]> {
    return await this.get(`/api/companies`);
  }
  async getCompany(id: string): Promise<ICompany> {
    return await this.get(`/api/companies/${encodeURIComponent(id)}`);
  }
  async createNewCompany(company: ICompany): Promise<ICompany> {
    return await this.update(`/api/companies`, 'POST', company);
  }
  async updateCompany(id: string, company: ICompany): Promise<ICompany> {
    return await this.update(`/api/companies/${encodeURIComponent(id)}`, 'PATCH', company);
  }
  async deleteCompany(id: string): Promise<string> {
    return this.delete(`/api/companies/${encodeURIComponent(id)}`);
  }

}
