import {Box, Button, Fab, Link, Tooltip} from "@mui/material";
import { IEstimatingRequest } from "../../api/ApiClient";
import styles from './RequestActions.module.css'
import {AttachFile, CloudDone, Task, Description, Loop, Edit} from "@mui/icons-material";
import {useAuthContext} from "../../context/authContext";

interface IRequestActionProps {
  request: IEstimatingRequest;
  large: boolean;
  onEdit: () => void;
}

function RequestActions(props: IRequestActionProps) {
  const authContext = useAuthContext();
  const {request, large, onEdit} = props;
  const isAdmin = authContext.isAdmin();
  let apiRoot = process.env.REACT_APP_APIROOT || "";

  return (
    <Box className={styles.container}>
      <Tooltip title={"Edit Request"} arrow>
        <Box>
          {!large &&
              (
                  <Fab color="primary" size={"small"} aria-label="go" sx={{
                    ":hover":{
                      backgroundColor:"primary.light"
                    }
                  }} onClick={onEdit}>
                    <Edit />
                  </Fab>
              )}
          {large &&
              (
                  <Button color="primary" variant={"contained"} size={"small"} aria-label="go" sx={{
                    ":hover":{
                      backgroundColor:"primary.light"
                    }
                  }} onClick={onEdit}>
                    <Edit />
                    Edit
                  </Button>
              )}
        </Box>
      </Tooltip>
      <Tooltip title={"Download your uploaded estimates"} arrow>
        <Box>
          <Link href={apiRoot + `/api/requests/${request.id}/download/source`} underline={"none"}>
            {!large &&
              (
                <Fab color="primary" size={"small"} aria-label="go" sx={{
                  ":hover":{
                    backgroundColor:"primary.light"
                  }
                }}>
                  <Description />
                </Fab>
              )}
            {large &&
              (
                <Button color="primary" variant={"contained"} size={"small"} aria-label="go" sx={{
                  ":hover":{
                    backgroundColor:"primary.light"
                  }
                }}>
                  <Description />
                  Estimates
                </Button>
              )}
          </Link>
        </Box>
      </Tooltip>
      {request.status == 'Ready' && (
        <Tooltip title={"Download the results"} arrow>
        <Box>
        <Link href={apiRoot + `/api/requests/${request.id}/download/result`} underline={"none"}>
          {!large &&
            (
              <Fab color="primary" size={"small"} aria-label="go" sx={{
                ":hover":{
                  backgroundColor:"primary.light"
                }
              }}>
                <Task />
              </Fab>
            )
          }
          {large &&
            (
              <Button color="primary" variant={"contained"} size={"small"} aria-label="go" sx={{
                ":hover":{
                  backgroundColor:"primary.light"
                }
              }}>
                <Task />
                Results
              </Button>
            )}
        </Link>
        </Box>
        </Tooltip>
      )}
      {/*
      {request.status != 'Ready' && isAdmin && (
        <>
        {!large &&
          (
            <Fab color="primary" size={"small"} aria-label="go" sx={{
              ":hover":{
                backgroundColor:"primary.light"
              }
            }}>
              <Loop />
            </Fab>
          )
        }
        {large &&
          (
            <Button color="primary" variant={"contained"} size={"small"} aria-label="go" sx={{
              ":hover":{
                backgroundColor:"primary.light"
              }
            }}>
              <Loop />
              Restart
            </Button>
          )}
        </>
      )}
      */}
    </Box>
  );
}

export default RequestActions
