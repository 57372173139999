import {Box, Button, Chip, Fab, Tooltip, Typography} from "@mui/material";
import styles from './RequestActions.module.css'
import {AttachFile, CloudDone, Download, FileDownload, Send} from "@mui/icons-material";
import { IEstimatingRequest } from "../../api/ApiClient";

interface IRequestStatusProps {
  request: IEstimatingRequest;
}

export function setStatusColor(status: string) {
  switch(status) {
    case 'Pending':
      return 'warning.main';
    case 'Error':
      return 'error.main';
    case 'Ready':
      return 'success.main';
    default:
      return 'primary.main';
  }
}

function RequestStatus(props: IRequestStatusProps) {
  const {request} = props;

  return (
    <Chip
      label={request.status}
      sx={{borderColor: setStatusColor(request.status), color: setStatusColor(request.status)}}
      variant="outlined"
    />
  );
}

export default RequestStatus
