import React from "react";
import {Box, Tooltip, Typography} from "@mui/material";
import {dateTimeFormatter} from "../../util/DateUtil";
import {ICompany} from "../../api/ApiClient";

interface IResultTooltipProps {
  company:ICompany;
}

export function CompanyTooltip(props: React.PropsWithChildren<IResultTooltipProps>) {
  const {company, children} = props;

  return (
    <Tooltip title={
      <Box>
        <Typography>{company.name}</Typography>
        <div>{`Created: ${dateTimeFormatter(company.dateCreated)}`}</div>
        <div>{`Groups: ${company.companyGroups}`}</div>
      </Box>
    } arrow>
      <Box>
        {children}
      </Box>
    </Tooltip>
  );
}
