import {Box, Button, Fab, Link, Tooltip} from "@mui/material";
import {IUser} from "../../api/ApiClient";
import styles from './UsersActions.module.css'
import {AttachFile, CloudDone, Download, Edit, FileDownload, Send} from "@mui/icons-material";

interface IRequestActionProps {
  user: IUser;
  large: boolean;
  onEdit: () => void;
}

function UsersActions(props: IRequestActionProps) {
  const {user, large, onEdit} = props;

  return (
    <Box className={styles.container}>
      <Tooltip title={"Edit User"} arrow>
        <Box>
        {!large &&
          (
            <Fab color="primary" size={"small"} aria-label="go" sx={{
              ":hover":{
                backgroundColor:"primary.light"
              }
            }} onClick={onEdit}>
              <Edit />
            </Fab>
          )}
        {large &&
          (
            <Button color="primary" variant={"contained"} size={"small"} aria-label="go" sx={{
              ":hover":{
                backgroundColor:"primary.light"
              }
            }} onClick={onEdit}>
              <Edit />
              Edit
            </Button>
          )}
        </Box>
      </Tooltip>
    </Box>
  );
}

export default UsersActions
