import {
  AppBar,
  Box,
  Button,
  Container,
  Drawer, Fab,
  Grid, IconButton, List,
  ListItem, ListItemButton, ListItemText,
  Menu,
  MenuItem, Paper, Popover, Toolbar,
  Typography, useMediaQuery,
  useTheme
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import {Link, Route, Routes, useLocation} from "react-router-dom";
import React, {useState} from "react";
import logo from '../content/logo.jpg';
import {useAuthContext} from "../context/authContext";
import {ApiClient} from "../api/ApiClient";

interface ILayoutProps {

}
function Layout(props: React.PropsWithChildren<ILayoutProps>) {
  const children = props.children;
  const theme = useTheme();
  const location = useLocation();
  const auth = useAuthContext();
  const [open, setOpen] = useState<boolean>();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  function onLogOut() {
    var apiClient = new ApiClient();
    apiClient.logout().then(() => {
      auth.logout();
    }).catch((error) => {
      alert(error);
    })
  }

  return (
    <>
      <Drawer
        anchor={"left"}
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { boxSizing: 'border-box' }
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            <ListItem key={"Requests"}>
              <Link to="/estimating/requests" style={{textDecoration:"none", color:"#000"}}>
                <ListItemButton onClick={() => setOpen(false)}>
                  <ListItemText>Requests</ListItemText>
                </ListItemButton>
              </Link>
            </ListItem>
            <ListItem key={"Companies"}>
              <Link to="/admin/companies" style={{textDecoration:"none", color:"#000"}}>
                <ListItemButton onClick={() => setOpen(false)}>
                  <ListItemText>Companies</ListItemText>
                </ListItemButton>
              </Link>
            </ListItem>
            <ListItem key={"Templates"}>
              <Link to="/admin/templates" style={{textDecoration:"none", color:"#000"}} >
                <ListItemButton onClick={() => setOpen(false)}>
                  <ListItemText>Templates</ListItemText>
                </ListItemButton>
              </Link>
            </ListItem>
            <ListItem key={"Users"}>
              <Link to="/admin/users" style={{textDecoration:"none", color:"#000"}} >
                <ListItemButton onClick={() => setOpen(false)}>
                  <ListItemText>Users</ListItemText>
                </ListItemButton>
              </Link>
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <AppBar position="static" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          {
            auth.isAdmin() && (
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={() => setOpen(true)}
              >
                <MenuIcon />
              </IconButton>
            )
          }
          <Box display={"flex"} justifyContent={"center"}>
            <img src={logo} alt={"The Shinn Group"} height={"50px"} />
          </Box>
          <Box display={"flex"} justifyContent={"center"} sx={{ flexGrow: 1 }}>
          <Typography variant="h6">
            {(location.pathname == '/' || location.pathname == '/estimating' || location.pathname == '/estimating/requests') && 'Requests'}
            {(location.pathname == '/admin' || location.pathname == '/admin/companies') && 'Companies '}
            {(location.pathname == '/admin/templates') && 'Templates'}
            {(location.pathname == '/admin/users') && 'Users'}
          </Typography>
          </Box>
          <Box display={"flex"} flexDirection={"column"} alignItems={"flex-end"} pr={2} pt={1} pb={1}>
              <AccountMenu onLogOut={onLogOut}/>
          </Box>
        </Toolbar>
      </AppBar>
      <Container maxWidth="xl" sx={{
        display:'flex',
        flexDirection:'column',
        justifyContent:'flex-start',
        height:'100%'
      }}>
        {children}
      </Container>
    </>
  );
}

function AccountMenu(props: {onLogOut:() => void}) {
  const {onLogOut} = props;
  const [accountOpen, setAccountOpen] = useState<boolean>();
  const auth = useAuthContext();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getInitials = (name:string | undefined):string => {
    const nameParts = auth.auth?.name.split(' ');
    if (!nameParts) {
      return "?"
    }

    return nameParts[0].substring(0,1) + (nameParts.length > 1 ? nameParts[1].substring(0,1) : '');
  }
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const initials = getInitials(auth.auth?.name);

  return (
      <>
        <Box>
          <Fab onClick={handleClick} sx={{backgroundColor:'#ddd'}}>
            {initials}
          </Fab>
        </Box>
        <Popover
            id={id}
            open={open}
            onClose={handleClose}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
        >
          <Paper sx={{ overflow: 'auto' }} elevation={3}>
            <Box p={2} display={'flex'} flexDirection={'column'} alignItems={'center'}>
              <Typography variant={"body1"}>
                {auth.auth?.name}
              </Typography>
              {auth.isAdmin() && (
              <Typography variant={"body2"} pb={1}>
                **Admin**
              </Typography>
              )}
              <Button variant={"contained"} onClick={onLogOut}>Log Out</Button>
            </Box>
          </Paper>
        </Popover>
      </>
  )

}
export default Layout;
