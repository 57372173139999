import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle, FormControl,
  Grid, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent,
  TextField,
  Typography
} from "@mui/material";
import {ApiClient, ICompany, ITemplate} from "../../api/ApiClient";
import React, {useEffect, useState} from "react";

interface ICompanyDetailProps {
  templates: ITemplate[],
  company: ICompany | null,
  onSave: (company: ICompany) => void,
  onCancel: () => void
}

export default function CompanyDetail(props: ICompanyDetailProps) {
  const {templates, company, onSave, onCancel} = props;
  const [changedName, setChangedName] = useState<string | null>(null);
  const [changedResultsTemplate, setChangedResultsTemplate] = useState<string | null>(null);
  const [changedMaterialsTemplate, setChangedMaterialsTemplate] = useState<string | null>(null);

  const isCreate = company?.id === undefined;
  const currentName = (changedName === null ? company?.name : changedName) || "";
  const currentResultsTemplate = (changedResultsTemplate === null ? company?.resultsTemplate : changedResultsTemplate) || "";
  const currentMaterialsTemplate = (changedMaterialsTemplate === null ? company?.materialsTemplate : changedMaterialsTemplate) || "";

  function onSaveClick() {
    if (!currentName || !currentResultsTemplate || !currentMaterialsTemplate)
    {
      alert("Both name and templates are required!");
      return;
    }

    let newCompany = {...company} as ICompany;
    newCompany.name = currentName;
    newCompany.resultsTemplate = currentResultsTemplate;
    newCompany.materialsTemplate = currentMaterialsTemplate;
    onSave(newCompany);
  }

  function onNameChange(event:  React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setChangedName(()=> event.target.value);
  }

  function handleResultsTemplateChange(event: SelectChangeEvent) {
    setChangedResultsTemplate(() => event.target.value);
  }

  function handleMaterialsTemplateChange(event: SelectChangeEvent) {
    setChangedMaterialsTemplate(() => event.target.value);
  }

  return (
    <Dialog open={company != null}>
      <DialogTitle>
        {isCreate ? `Create Company` : `Updating Company "${company!.name}" `}
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item p={1} xs={12} display={"flex"} flexDirection={"column"}>
            <TextField title={"Name"} placeholder={"Name"} onChange={onNameChange} value={currentName} required/>
          </Grid>
          <Grid item p={1} xs={12} display={"flex"} flexDirection={"column"}>
            {!templates ? (
                <Typography>Loading Materials Templates...</Typography>
            ) : (
                <FormControl>
                  <InputLabel id="materialstemplate-label">Materials Template</InputLabel>
                  <Select
                      labelId="materialstemplate-label"
                      id="materialstemplate-select"
                      value={currentMaterialsTemplate}
                      onChange={handleMaterialsTemplateChange}
                      label="Materials Template"
                      required
                  >
                    <MenuItem disabled value="">
                      <em>Please Select</em>
                    </MenuItem>
                    {
                      templates
                        .sort((a, b) => {
                          return a?.name.localeCompare(b?.name);
                        })
                        .filter(templates => templates.type === "Materials")
                        .map(template =>
                            <MenuItem key={template.id} value={template.id}>{template.name}</MenuItem>
                        )
                    }
                  </Select>
                </FormControl>
            )}
          </Grid>
          <Grid item p={1} xs={12} display={"flex"} flexDirection={"column"}>
            {!templates ? (
                <Typography>Loading Results Templates...</Typography>
            ) : (
                <FormControl>
                  <InputLabel id="resultsTemplate-label">Results Template</InputLabel>
                  <Select
                      labelId="resultsTemplate-label"
                      id="resultsTemplate-select"
                      value={currentResultsTemplate}
                      onChange={handleResultsTemplateChange}
                      label="Results Template"
                      required
                  >
                    <MenuItem disabled value="">
                      <em>Please Select</em>
                    </MenuItem>
                    {
                      templates
                        .sort((a, b) => {
                          return a?.name.localeCompare(b?.name);
                        })
                        .filter(templates => templates.type === "Results")
                        .map(template =>
                            <MenuItem key={template.id} value={template.id}>{template.name}</MenuItem>
                        )
                    }
                  </Select>
                </FormControl>
            )}
          </Grid>
          <Grid item p={1} xs={12} display={"flex"} justifyContent={"space-between"}>
            <Button variant={"contained"} onClick={() => onCancel()}>
              Cancel
            </Button>
            {
              templates && (
                <Button variant={"contained"} onClick={() => onSaveClick()}>
                  Save
                </Button>
              )
            }
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
