import {Box, Button, Dialog, DialogContent, DialogTitle, FormControl, Grid, TextField, Typography} from "@mui/material";
import React, {useState} from "react";
import {ApiClient, IUser} from "../../api/ApiClient";

interface ILoginProps {
    loginSuccessful: (user:IUser) => void
}

export default function Login(props: ILoginProps) {
    const [loading, setLoading] = useState<boolean>(false);
    const [errorState, setSetErrorState] = useState<string>('');

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        var data = new FormData(event.target as HTMLFormElement);
        if (data.get("username") && data.get("password")) {
            const username = data.get("username") as string;
            const password = data.get("password") as string;

            const apiClient = new ApiClient();
            setLoading(true);
            apiClient.login(username, password).then((user:IUser) => {
                props.loginSuccessful(user);
            }).catch(reason => {
                setSetErrorState(reason.message ?? 'Login Failed');
            }).finally(() => {
                setLoading(false);
            })
        }
        else
        {
            setSetErrorState('Both Username and Password are required!');
        }
    }

    return (
        <Box>
            <Dialog open={true}>
                <DialogTitle>
                    <img src={"/logo.png"}/>
                    <Box pt={4}>Sign in with your email and password</Box>
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit}>
                        <Box display={"flex"} flexDirection={"column"} minWidth={300}>
                            <TextField title={"Email"} placeholder={"Email"} name={"username"} required sx={{paddingTop:2}} />
                            <TextField title={"Password"} placeholder={"Password"} name={"password"} type={"password"} required sx={{paddingTop:2, paddingBottom:2}}/>
                            {errorState && (
                                <Typography sx={{color:'red'}}>{errorState}</Typography>
                            )}
                            <Button variant={"contained"} type={"submit"} disabled={loading}>{!loading ? `Login` : `Logging in...`}</Button>
                        </Box>
                    </form>
                </DialogContent>
            </Dialog>
        </Box>
    );
}