import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import {Box, Paper, Tooltip, useMediaQuery, useTheme} from "@mui/material";
import {Upload} from "@mui/icons-material";

interface IUploadDropzoneProps {
  tooltip:string;
  onFilesUploaded : (files: File[]) => void;
}

function UploadDropzone(props: IUploadDropzoneProps) {
  const { tooltip, onFilesUploaded } = props;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const onDrop = useCallback((acceptedFiles: File[]) => {
    const xlsFiles = acceptedFiles.filter(file => file.name.endsWith(".xls") || file.name.endsWith(".xlsx") || file.name.endsWith(".xlsm"));
    onFilesUploaded(xlsFiles);
  }, []);
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  return (
    <Paper {...getRootProps()} sx={{
      backgroundColor:theme.palette.primary.main,
      width:isSmallScreen ? 200 : 400,
      display:'flex',
      flexDirection:'row',
      alignItems:'center',
      justifyContent:'center',
      fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
      fontWeight: 500,
      fontSize: '0.8125rem',
      lineHeight: 1.75,
      letterSpacing: '0.02857em',
      textTransform: 'uppercase',
      cursor: 'pointer'
    }}>
      <input {...getInputProps()} />
        <Upload />
      {
        isDragActive ?
          <span>Drop the files here ...</span> :
          <Tooltip title={tooltip} arrow>
            <Box sx={{display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
                {!isSmallScreen && (<><div>Drag new xls here</div><div>&nbsp;or&nbsp;</div></>)}
                <div>click to select</div>
            </Box>
          </Tooltip>
      }
    </Paper>
  )
}

export default UploadDropzone;
