
export function dateTimeFormatter(value:string) {
  if (!value) {
    return "";
  }
  const date = new Date(value);
  if (date.getFullYear() < 2000) {
    return "";
  }
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
}
