import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle, FormControl,
  Grid, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent,
  TextField,
  Typography
} from "@mui/material";
import {ApiClient, ICompany, ITemplate, IUser, IUserWithPassword} from "../../api/ApiClient";
import React, {useEffect, useState} from "react";

interface IUserDetailProps {
  companies: ICompany[],
  user: IUser | null,
  onSave: (user: IUser) => void,
  onCancel: () => void
}

export default function UserDetail(props: IUserDetailProps) {
  const {companies, user, onSave, onCancel} = props;
  const [enablePasswordChange, setEnablePasswordChange] = useState<boolean>(false);
  const [changedName, setChangedName] = useState<string | null>(null);
  const [changedEmail, setChangedEmail] = useState<string | null>(null);
  const [changedPassword, setChangedPassword] = useState<string | null>(null);
  const [changedConfirmedPassword, setChangedConfirmedPassword] = useState<string | null>(null);
  const [changedCompany, setChangedCompany] = useState<string | null>(null);
  const [changedGroup, setChangedGroup] = useState<string | null>(null);
  const [changedAccessLevel, setChangedAccessLevel] = useState<string | null>(null);
  const [saveStarted, setSaveStarted] = useState<boolean>(false);

  const isCreate = user?.id === undefined;
  const currentName = (changedName === null ? user?.name : changedName) || "";
  const currentEmail = (changedEmail === null ? user?.email : changedEmail) || "";
  const currentCompany = (changedCompany === null ? user?.company : changedCompany) || "";
  const currentGroup = (changedGroup === null ? user?.group : changedGroup) || "";
  const currentAccessLevel = (changedAccessLevel === null ? user?.accessLevel : changedAccessLevel) || "User";

  function allowSave() {
    return currentName && currentEmail && currentCompany && currentGroup && currentAccessLevel;
  }

  function checkPassword() {
    //Password changes are checked during creation and during update when enablePasswordChange is true
    return isCreate || enablePasswordChange;

    if (isCreate) {
      return true;
    }
    if (enablePasswordChange) {
      return true;
    }
    return (isCreate || enablePasswordChange) && (!changedPassword && !changedConfirmedPassword);
  }

  function passwordMatches() {
    return changedPassword != null && changedPassword !== '' && changedPassword === changedConfirmedPassword;
  }

  function togglePasswordChange() {
    setEnablePasswordChange(value=>!value);
  }

  function onSaveClick() {
    if (!allowSave())
    {
      alert("All fields are required!");
      return;
    }

    let newUser = {...user} as IUserWithPassword;
    newUser.name = currentName;
    newUser.company = currentCompany;
    newUser.group = currentGroup;
    newUser.accessLevel = currentAccessLevel;
    newUser.email = currentEmail;

    if (checkPassword()) {
      if (!passwordMatches())
      {
        alert("Passwords need to match!");
        return;
      }
      else
      {
        newUser.password = changedPassword!;
      }
    }

    setSaveStarted(true);
    onSave(newUser);
  }

  function onNameChange(event:  React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setChangedName(()=> event.target.value);
  }

  function onEmailChange(event:  React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setChangedEmail(()=> event.target.value);
  }

  function onPasswordChange(event:  React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setChangedPassword(()=> event.target.value);
  }

  function onConfirmedPasswordChange(event:  React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setChangedConfirmedPassword(()=> event.target.value);
  }

  function handleCompanyChange(event: SelectChangeEvent) {
    setChangedCompany(() => event.target.value);
  }

  function onGroupChange(event:  React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setChangedGroup(()=> event.target.value);
  }

  function handleAccessLevelChange(event: SelectChangeEvent) {
    setChangedAccessLevel(() => event.target.value);
  }

  const enableSaveButton = !!companies && allowSave() && (!checkPassword() || passwordMatches())
  return (
    <Dialog open={user != null}>
      <DialogTitle>
        {isCreate ? `Create User` : `Updating User "${user!.name}" `}
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item p={1} xs={12} display={"flex"} flexDirection={"column"}>
            <Typography>Account:</Typography>
          </Grid>
          <Grid item p={1} xs={12} display={"flex"} flexDirection={"column"}>
            <TextField title={"Name"} placeholder={"Name"} label={"Name"} onChange={onNameChange} value={currentName} required/>
          </Grid>
          <Grid item p={1} xs={12} display={"flex"} flexDirection={"column"}>
            {!companies ? (
              <Typography>Loading Companies...</Typography>
            ) : (
              <FormControl>
                <InputLabel id="company-label">Company</InputLabel>
                <Select
                  labelId="company-label"
                  id="company-select"
                  value={currentCompany}
                  onChange={handleCompanyChange}
                  label="Company"
                  required
                >
                  <MenuItem disabled value="">
                    <em>Please Select</em>
                  </MenuItem>
                  {
                    companies.map(company =>
                      <MenuItem key={company.id} value={company.id}>{company.name}</MenuItem>
                    )
                  }
                </Select>
              </FormControl>
            )}
            </Grid>
          <Grid item p={1} xs={12} display={"flex"} flexDirection={"column"}>
            <TextField title={"Group"} placeholder={"Group (default=All)"} label={"Group"} onChange={onGroupChange} value={currentGroup} required/>
          </Grid>
          <Grid item p={1} xs={12} display={"flex"} flexDirection={"column"}>
              <FormControl>
                <InputLabel id="accessLevel-label">Access Level</InputLabel>
                <Select
                    labelId="accessLevel-label"
                    id="accessLevel-select"
                    value={currentAccessLevel}
                    onChange={handleAccessLevelChange}
                    label="Access Level"
                    required
                >
                  <MenuItem disabled value="">
                    <em>Please Select</em>
                  </MenuItem>
                  <MenuItem key={"Administrator"} value={"Administrator"}>{"Administrator"}</MenuItem>
                  <MenuItem key={"Company"} value={"Company"}>{"Company"}</MenuItem>
                  <MenuItem key={"Group"} value={"Group"}>{"Group"}</MenuItem>
                  <MenuItem key={"User"} value={"User"}>{"User"}</MenuItem>
                </Select>
              </FormControl>
          </Grid>
          <Grid item p={1} xs={12} display={"flex"} flexDirection={"column"}>
            <TextField title={"Email"} placeholder={"Email"} label={"Email"} onChange={onEmailChange} value={currentEmail} required/>
          </Grid>
          <Grid item p={1} xs={12} display={"flex"} flexDirection={"column"}>
            {isCreate && (<Typography>Set Password:</Typography>)}
            {!isCreate && (
                <Button variant={"contained"} onClick={() => togglePasswordChange()}>
                  {!enablePasswordChange ? `Change Password` : `Don't Change Password`}
                </Button>
            )}
          </Grid>
          {(isCreate || enablePasswordChange) && (
            <>
              <Grid item p={1} xs={12} display={"flex"} flexDirection={"column"}>
                <TextField title={"Password"} placeholder={"Password"} label={"Password"} type={"password"}  onChange={onPasswordChange} />
              </Grid>
              <Grid item p={1} xs={12} display={"flex"} flexDirection={"column"}>
                <TextField title={"Confirm Password"} placeholder={"Confirm Password"} label={"Confirm Password"} type={"password"} onChange={onConfirmedPasswordChange} />
              </Grid>
            </>
          )}
          <Grid item p={1} xs={12} display={"flex"} justifyContent={"space-between"}>
            <Button variant={"contained"} onClick={() => onCancel()}>
              Cancel
            </Button>
            {!saveStarted && (
              <Button variant={"contained"} onClick={() => onSaveClick()} disabled={!enableSaveButton}>
                Save
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
