import Users from "./Users";
import {useEffect, useState} from "react";
import {ApiClient, ICompany, ITemplate, IUser} from "../../api/ApiClient";

function UsersLoader() {
  const [companies, setCompanies] = useState<ICompany[]>();
  const [users, setUsers] = useState<IUser[] | undefined>(undefined);
  const [usersVersion, setUsersVersion] = useState<number>(0)

  function onUserRefresh() {
    setUsersVersion(prevNumber => prevNumber + 1);
  }

  useEffect(() => {
    const apiClient = new ApiClient();

    apiClient.getCompanies().catch( () => {
      alert('Failed to get companies!');
    }).then((companies) => {
      setCompanies(companies!);
    });
  }, []);

  useEffect(()=> {
      const apiClient = new ApiClient();
      apiClient.getUsers().then(users => {
        users.sort((a,b) => {
          let result = a.company.localeCompare(b.company);
          if (result === 0) {
            result = a.name.localeCompare(b.name);
          }

          return result;
        });
        setUsers(users);
      }).catch(reason => {
        alert('Error loading users: ' + reason);
      })
    },
    [users && usersVersion]
  )

  return (
    <>
      { companies &&
        (
          <Users companies={companies!} users={users} onUserRefresh={onUserRefresh}/>
        )
      }
    </>
  );
}

export default UsersLoader;
