import React, {useEffect, useState} from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import {AppBar, Box, Dialog, DialogContent, DialogTitle} from '@mui/material';
import {createTheme, ThemeProvider} from "@mui/material/styles";
import AppRouter from "./routes/router";
import {ApiClient, IUser} from "./api/ApiClient";
import {AuthContext, AuthContextType} from "./context/authContext";
import Login from "./components/login/Login";

const theme = createTheme({
  palette: {
    primary: {
      main: '#FFC845'
    },
    secondary: {
      main: '#53565A'
    }
  },
  components: {
    MuiAppBar: {
      defaultProps: {
        sx: {
          backgroundColor:'#FFC845'
        }
      }
    }
  }
});

function AppLoadingDialog(errorState: string) {
  let dialodTitle = 'Application Loading... Please wait!';
  let dialogContent = (
      <>
        <img src={"/logo.png"}/>
      </>
  )
  if (errorState !== '') {
    dialodTitle = 'Application failed to start. Please try again in a moment!';
    dialogContent = (
        <>
          If the problem persists try the following:<br/>
          &emsp;1. Empty the browser cache<br/>
          &emsp;2. Close the browser window.<br/>
          <br/>
          If the steps above do not work, please contact us at <a
            href={'mailto:help@shinntegration.com'}>help@shinntegration.com</a>!
        </>
    );
  }
  return (
      <Box>
        <Dialog open={true}>
          <DialogTitle>
            {dialodTitle}
          </DialogTitle>
          <DialogContent>
            {dialogContent}
          </DialogContent>
        </Dialog>
      </Box>
  );
}

function App() {
  const [errorState, setSetErrorState] = useState<string>('');
  const [auth, setAuth] = useState<IUser>();

  useEffect(()=> {
      const apiClient = new ApiClient();
      apiClient.getMe().then(auth => {
        setAuth(auth);
      }).catch(reason => {
        setSetErrorState(reason.message ?? 'Unknown Error');
      });
    },
    []
  )

  function loginSuccessful(auth:IUser) {
    const apiClient = new ApiClient();
    apiClient.getMe().then(auth => {
      setAuth(auth);
    }).catch(reason => {
      setSetErrorState(reason.message ?? 'Unknown Error');
      setAuth(undefined);
    });
  }

  function onLogout() {
    const apiClient = new ApiClient();
    apiClient.getMe().then(auth => {
      setAuth(auth);
    }).catch(reason => {
      setSetErrorState(reason.message ?? 'Unknown Error');
      setAuth(undefined);
    });
  }

  if (!auth) {
    return AppLoadingDialog(errorState);
  }

  if (!auth.email || !auth.name || !auth.company || !auth.group || !auth.accessLevel) {
    return <Login loginSuccessful={loginSuccessful}/>
  }

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AuthContext.Provider value={new AuthContextType(auth, onLogout)}>
          <Box sx={{
            display:'flex',
            flexDirection:'column',
            justifyContent:'space-between',
            height:'100%'
          }}>
            <AppRouter />
            <Box p={1} sx={{
              display:'flex',
              flexDirection:'row',
              justifyContent:'center',
              flexWrap: 'wrap',
              backgroundColor:'#53565A',
              color:'#fff',
              a: {
                color:'#fff'
              }
            }}>
              <div>© 2022-2023 Shinn Consulting&nbsp;-&nbsp;</div><div>All Rights Reserved&nbsp;-&nbsp;</div><div><a href={'mailto:help@shinntegration.com'}>help@shinntegration.com</a></div>
            </Box>
          </Box>
        </AuthContext.Provider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
