import {Box} from "@mui/material";

export interface IMobileRowProps {
  primary: React.ReactNode
  secondary: React.ReactNode
}
function MobileRow(props: IMobileRowProps) {
  return (
    <Box sx={{display:'flex', flexDirection:'column', flexWrap:'wrap'}}>
      <Box>
        {props.primary}
      </Box>
      <Box>
        {props.secondary}
      </Box>
    </Box>
  )
}

export default MobileRow;
