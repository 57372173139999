import {useTheme} from "@mui/material";
import {
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton
} from "@mui/x-data-grid";
import React from "react";

export function CustomToolbar() {
  const theme = useTheme();

  return (
    <GridToolbarContainer sx={{backgroundColor: theme.palette.secondary.light}}>
      <GridToolbarFilterButton/>
      <GridToolbarDensitySelector/>
      <GridToolbarExport/>
    </GridToolbarContainer>
  );
}
