import Companies from "./Companies";
import {useEffect, useState} from "react";
import {ApiClient, ICompany, ITemplate} from "../../api/ApiClient";

function CompaniesLoader() {
  const [templates, setTemplates] = useState<ITemplate[]>();
  const [companies, setCompanies] = useState<ICompany[] | undefined>(undefined);
  const [companiesVersion, setCompaniesVersion] = useState<number>(0)

  function onCompanyRefresh() {
    setCompaniesVersion(prevNumber => prevNumber + 1);
  }

  useEffect(() => {
    const apiClient = new ApiClient();

    apiClient.getTemplates().catch( () => {
      alert('Failed to get templates!');
    }).then((templates) => {
      setTemplates(templates!);
    });
  }, []);


  useEffect(()=> {
      const apiClient = new ApiClient();
      apiClient.getCompanies().then(companies => {
        companies.sort((a,b) => {
          return a.name.localeCompare(b.name);
        });
        setCompanies(companies);
      }).catch(reason => {
        alert('Error loading companies: ' + reason);
      })
    },
    [templates && companiesVersion]
  )

  return (
    <>
      { templates &&
        (
          <Companies templates={templates!} companies={companies} onCompanyRefresh={onCompanyRefresh}/>
        )
      }
    </>
  );
}

export default CompaniesLoader;
