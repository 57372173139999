import React, {useContext} from "react";
import {IApiClient, IUser} from "../api/ApiClient";

export class AuthContextType {
  auth: IUser | undefined;
  onLogout:() => void;

  constructor(auth:IUser, onLogout:()=>void) {
    this.auth = auth;
    this.onLogout = onLogout;
  }

  isAuthenticated():boolean {
    return !!this.auth;
  }

  isAdmin():boolean {
    return this.auth?.accessLevel === "Administrator";
  }

  logout() {
    this.auth = undefined;
    this.onLogout();
  }
}

export const AuthContext = React.createContext<AuthContextType | null>(null);

export const useAuthContext = () => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error('No AuthContext.Provider found when calling useAuthContext');
  }

  return authContext;
}
