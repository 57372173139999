import React, {useEffect, useState} from "react";
import {DataGrid, GridColDef, GridSelectionModel} from '@mui/x-data-grid';
import CompaniesActions from "./CompaniesActions";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid, Tooltip,
  useMediaQuery,
  useTheme
} from "@mui/material";
import {Create, Delete} from "@mui/icons-material";
import {CustomToolbar} from "../CustomToolbar";
import MobileRow from "../MobileRow";
import {ApiClient, ICompany, ITemplate} from "../../api/ApiClient";
import {dateTimeFormatter} from "../../util/DateUtil";
import {CompanyTooltip} from "./CompanyTooltip";
import CompanyDetail from "./CompanyDetail";
import {RequestTooltip} from "../requests/RequestTooltip";

const largeScreenColumns = {
  name:true,
  template:true,
  companyGroups: true,
  mobilerow:false,
  actionssmall:false,
  actionslarge:true,
  dateCreated: true,
}

const smallScreenColumns = {
  name:false,
  template:false,
  companyGroups: false,
  mobilerow:true,
  actionssmall:true,
  actionslarge:false,
  dateCreated: false,
}

interface ICompaniesProps {
  templates:ITemplate[];
  companies?:ICompany[];
  onCompanyRefresh:() => void;
}


function Companies(props : ICompaniesProps) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [columnsVisible, setColumnsVisible] = useState(largeScreenColumns);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [detailsCompany, setDetailsCompany] = useState<ICompany | null>(null);
  const { templates, companies, onCompanyRefresh } = props;
  const rows = companies ? companies : [];

  const templatesById = Object.fromEntries(templates.map(template => [template.id, template.name]));

  function onSave(company: ICompany) {
    const apiClient = new ApiClient();

    if (company.id) {
      apiClient.updateCompany(company.id, company).catch( () => {
        alert('Failed to update the company!');
      }).finally(() => {
        setDetailsCompany(null);
        onCompanyRefresh();
      });
    } else {
      apiClient.createNewCompany(company).catch( () => {
        alert('Failed to create the company!');
      }).finally(() => {
        setDetailsCompany(null);
        onCompanyRefresh();
      });
    }
  }

  function onCancel() {
    setDetailsCompany(null);
  }

  function onCreate() {
    const company = {} as ICompany;
    setDetailsCompany(company);
  }

  function onEdit(company: ICompany) {
    setDetailsCompany(company);
  }

  function onDelete(selectionModel: GridSelectionModel) {
    if (!window.confirm(`Do you want to delete ${selectionModel.length} companies?`))
    {
      return;
    }

    const apiClient = new ApiClient();

    const deleteCompanies = selectionModel.map( rowId => {
      return apiClient.deleteCompany(rowId.toString());
    })

    Promise.all(deleteCompanies).catch( (error) => {
      alert('One or more deletes failed!');
    }).finally(() => {
      onCompanyRefresh();
    });
  }

  function getTemplate(id: string) {
    return templatesById[id];
  }

  const columns: GridColDef[] = [
    {
      field:'mobilerow',
      headerName:'Info',
      flex: 90,
      renderCell:(params) =>
        (
          <CompanyTooltip company={params.row}>
            <MobileRow
              primary={params.row.name}
              secondary={`${dateTimeFormatter(params.row.dateCreated)} - ${params.row.materialsTemplate}`} />
          </CompanyTooltip>
        )
    },
    { field: 'dateCreated', headerName: 'Created', flex: 10, renderCell:params => {
        return (
          <CompanyTooltip company={params.row}>
            {dateTimeFormatter(params.value)}
          </CompanyTooltip>
        );
      }
    },
    { field: 'name', headerName: 'Name', flex: 20 },
    { field: 'materialsTemplate', headerName: 'Materials Template', flex: 20, valueFormatter:params => getTemplate(params.value) },
    { field: 'resultsTemplate', headerName: 'Results Template', flex: 20, valueFormatter:params => getTemplate(params.value) },
    { field: 'companyGroups', headerName: 'Groups', flex: 10 },
    {
      field:'actionssmall',
      headerName:'Actions',
      type:'actions',
      flex: 10,
      minWidth: 120,
      renderCell:(params) =>
        (
          <CompanyTooltip company={params.row}>
            <CompaniesActions company={params.row} large={false} onEdit={() => onEdit(params.row)} />
          </CompanyTooltip>
        )
    },
    {
      field:'actionslarge',
      headerName:'Actions',
      type:'actions',
      flex: 10,
      minWidth: 120,
      renderCell:(params) =>
        (
          <CompanyTooltip company={params.row}>
            <CompaniesActions company={params.row} large={true} onEdit={() => onEdit(params.row)} />
          </CompanyTooltip>
        )
    }
  ];

  useEffect(() => {
    setColumnsVisible(isSmallScreen ? smallScreenColumns : largeScreenColumns);
  }, [isSmallScreen]);

  return (
    <>
      <Dialog open={uploadProgress > 0}>
        <DialogTitle>
          Saving...
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Saving. Please wait!
          </DialogContentText>
        </DialogContent>
      </Dialog>
      { detailsCompany && (
        <CompanyDetail templates={templates} company={detailsCompany} onSave={onSave} onCancel={onCancel} />
      )}
      <Grid p={1} sx={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
        <Tooltip title={"Create a new company"} arrow>
          <Button variant={"contained"} size={"small"} onClick={() => onCreate()}><Create />New Company</Button>
        </Tooltip>
        <Tooltip title={"Delete selected company"} arrow>
          <Button variant={"contained"} size={"small"} onClick={() => onDelete(selectionModel)}><Delete />Delete</Button>
        </Tooltip>
      </Grid>
      <DataGrid
        loading={!companies}
        rows={rows}
        columns={columns}
        pageSize={100}
        rowsPerPageOptions={[25,50,100]}
        checkboxSelection
        components={{
          Toolbar: CustomToolbar,
        }}
        columnVisibilityModel={columnsVisible}
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
        }}
        selectionModel={selectionModel}
        getRowId={company => company.id}
      />
    </>
  );
}

export default Companies;

