import {Box, Button, Fab, Link, Tooltip} from "@mui/material";
import { ITemplate } from "../../api/ApiClient";
import styles from './TemplateActions.module.css'
import {AttachFile, CloudDone, Download, Edit, FileDownload, Send} from "@mui/icons-material";

interface ITemplateActionProps {
  template: ITemplate;
  large: boolean;
  onEdit: () => void;
}

function TemplateActions(props: ITemplateActionProps) {
  const {template, large, onEdit} = props;
  let apiRoot = process.env.REACT_APP_APIROOT || "";

  return (
    <Box className={styles.container}>
      <Tooltip title={"Edit Template"} arrow>
        <Box>
        {!large &&
          (
            <Fab color="primary" size={"small"} aria-label="go" sx={{
              ":hover":{
                backgroundColor:"primary.light"
              }
            }} onClick={onEdit}>
              <Edit />
            </Fab>
          )}
        {large &&
          (
            <Button color="primary" variant={"contained"} size={"small"} aria-label="go" sx={{
              ":hover":{
                backgroundColor:"primary.light"
              }
            }} onClick={onEdit}>
              <Edit />
              Edit
            </Button>
          )}
        </Box>
      </Tooltip>
      <Tooltip title={"Download the template"} arrow>
        <Box>
          <Link href={apiRoot + `/api/templates/${encodeURIComponent(template.id)}/download/template`} underline={"none"}>
            {!large &&
              (
                <Fab color="primary" size={"small"} aria-label="go" sx={{
                  ":hover":{
                    backgroundColor:"primary.light"
                  }
                }}>
                  <AttachFile />
                </Fab>
              )}
            {large &&
              (
                <Button color="primary" variant={"contained"} size={"small"} aria-label="go" sx={{
                  ":hover":{
                    backgroundColor:"primary.light"
                  }
                }}>
                  <AttachFile />
                  Template
                </Button>
              )}
          </Link>
        </Box>
      </Tooltip>
    </Box>
  );
}

export default TemplateActions
