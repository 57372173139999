import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle, FormControl,
  Grid, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent,
  TextField,
  Typography
} from "@mui/material";
import {ApiClient, ITemplate} from "../../api/ApiClient";
import React, {useEffect, useState} from "react";

interface ITemplateDetailProps {
  template: ITemplate | null,
  onSave: (template: ITemplate) => void,
  onCancel: () => void
}

export default function TemplateDetail(props: ITemplateDetailProps) {
  const {template, onSave, onCancel} = props;
  const [changedName, setChangedName] = useState<string | null>(null);
  const [changedType, setChangedType] = useState<string | null>(null);
  const [saveStarted, setSaveStarted] = useState<boolean>(false);

  const isCreate = template?.id === undefined;
  const currentName = (changedName === null ? template?.name : changedName) || "";
  const currentType = (changedType === null ? template?.type : changedType) || "";

  function onSaveClick() {
    if (!currentName || !currentType)
    {
      alert("Name and Type are required!");
      return;
    }

    if (currentType !== 'Results' && currentType !== 'Materials')
    {
      alert("Type has to be Results or Materials");
      return;
    }

    setSaveStarted(true);
    let newTemplate = {...template} as ITemplate;
    newTemplate.name = currentName;
    newTemplate.type = currentType;
    onSave(newTemplate);
  }

  function onNameChange(event:  React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setChangedName(()=> event.target.value);
  }

  function handleTypeChange(event: SelectChangeEvent) {
    setChangedType(() => event.target.value);
  }

  return (
    <Dialog open={template != null}>
      <DialogTitle>
        {isCreate ? `Create Template` : `Updating Template "${template!.name}" `}
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item p={1} xs={12} display={"flex"} flexDirection={"column"}>
            <TextField title={"Name"} placeholder={"Name"} onChange={onNameChange} value={currentName} required/>
          </Grid>
          <Grid item p={1} xs={12} display={"flex"} flexDirection={"column"}>
            <FormControl>
              <InputLabel id="type-label">Type</InputLabel>
              <Select
                  labelId="type-label"
                  id="type-select"
                  value={currentType}
                  onChange={handleTypeChange}
                  label="Type"
                  required
              >
                <MenuItem disabled value="">
                  <em>Please Select</em>
                </MenuItem>
                <MenuItem key={"Materials"} value={"Materials"}>{"Materials"}</MenuItem>
                <MenuItem key={"Results"} value={"Results"}>{"Results"}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item p={1} xs={12} display={"flex"} justifyContent={"space-between"}>
            <Button variant={"contained"} onClick={() => onCancel()}>
              Cancel
            </Button>
            {!saveStarted && (
                <Button variant={"contained"} onClick={() => onSaveClick()}>
                  Save
                </Button>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
