import React from "react";
import {Box, Tooltip, Typography} from "@mui/material";
import {dateTimeFormatter} from "../../util/DateUtil";
import {IEstimatingRequest} from "../../api/ApiClient";

interface IResultTooltipProps {
  request:IEstimatingRequest;
}

export function RequestTooltip(props: React.PropsWithChildren<IResultTooltipProps>) {
  const {request, children} = props;

  return (
    <Tooltip title={
      <Box>
        <Typography>{request.status}</Typography>
        <div>{`Created: ${dateTimeFormatter(request.dateCreated)}`}</div>
        {request.datePending && (
          <div>{`Pending: ${dateTimeFormatter(request.datePending)}`}</div>
        )}
        {request.dateReady && (
          <div>{`Ready: ${dateTimeFormatter(request.dateReady)}`}</div>
        )}
      </Box>
    } arrow>
      <Box>
        {children}
      </Box>
    </Tooltip>
  );
}
