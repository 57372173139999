import React, {useEffect, useState} from "react";
import {DataGrid, GridColDef, GridSelectionModel} from '@mui/x-data-grid';
import RequestActions from "./RequestActions";
import UploadDropZone from "../UploadDropZone";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid, Tooltip,
  useMediaQuery,
  useTheme
} from "@mui/material";
import {Delete} from "@mui/icons-material";
import {CustomToolbar} from "../CustomToolbar";
import MobileRow from "../MobileRow";
import {ApiClient, ICompany, IEstimatingRequest, ITemplate, IUser} from "../../api/ApiClient";
import RequestStatus from "./RequestStatus";
import {dateTimeFormatter} from "../../util/DateUtil";
import {RequestTooltip} from "./RequestTooltip";
import RequestDetail from "./RequestDetail";
import {useAuthContext} from "../../context/authContext";

const largeScreenColumns = {
  status: true,
  name:true,
  company:true,
  companyGroup:true,
  owner:true,
  mobilerow:false,
  actionssmall:false,
  actionslarge:true,
  dateCreated: true,
  datePending: true,
  dateReady: true,
}

const smallScreenColumns = {
  status: true,
  name:false,
  company:false,
  companyGroup:false,
  owner:false,
  mobilerow:true,
  actionssmall:true,
  actionslarge:false,
  dateCreated: false,
  datePending: false,
  dateReady: false,
}

interface IRequestsProps {
  users?:IUser[];
  companies?:ICompany[];
  requests?:IEstimatingRequest[];
  onRequestRefresh:() => void;
}

function Requests(props : IRequestsProps) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [columnsVisible, setColumnsVisible] = useState(largeScreenColumns);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const { users, companies, requests, onRequestRefresh } = props;
  const [detailsRequest, setDetailsRequest] = useState<IEstimatingRequest | null>(null);
  const rows = requests ? requests : [];
  const auth = useAuthContext();

  const usersById = users ? Object.fromEntries(users.map(user => [user.id, user.name])) : null;
  const companiesById = companies ? Object.fromEntries(companies.map(company => [company.id, company.name])) : null;

  function onFilesUploaded(files :File[]) {
    /*if (files.length > 1) {
      alert('Only uploading first file!');
    }*/
    if (files.length > 0) {
      setUploadProgress(files.length);
      for(const file of files) {
        const apiClient = new ApiClient();
        apiClient.createNewRequest(file).catch(reason => {
          alert('Upload failed!');
        }).finally(() => {
          onRequestRefresh();
          setUploadProgress(prevState => prevState - 1);
        });
      }
    } else {
      alert('No acceptable files found! Please upload an excel file (xls,xlsx)!');
    }
  }

  function onSave(request: IEstimatingRequest) {
    const apiClient = new ApiClient();

    if (request.id) {
      apiClient.updateRequest(request.id, request).catch( () => {
        alert('Failed to update the request!');
      }).finally(() => {
        setDetailsRequest(null);
        onRequestRefresh();
      });
    }
  }

  function onCancel() {
    setDetailsRequest(null);
  }

  function onEdit(request: IEstimatingRequest) {
    setDetailsRequest(request);
  }

  function onDelete(selectionModel: GridSelectionModel) {
    if (!window.confirm(`Do you want to delete ${selectionModel.length} requests?`))
    {
      return;
    }

    const apiClient = new ApiClient();

    const deleteRequests = selectionModel.map( rowId => {
      return apiClient.deleteRequest(rowId.toString());
    })

    Promise.all(deleteRequests).catch( (error) => {
      alert('One or more deletes failed!');
    }).finally(() => {
      onRequestRefresh();
    });
  }

  function getUserName(id: string) {
    return usersById ? (usersById[id] || "Unknown user") : "Loading Users...";
  }

  function getCompanyName(id: string) {
    return companiesById ? (companiesById[id] || "Unknown Company") : "Loading Companies...";
  }
  const columns: GridColDef[] = [
    {
      field:'mobilerow',
      headerName:'Info',
      flex: 90,
      renderCell:(params) =>
        (
          <RequestTooltip request={params.row}>
            <MobileRow
              primary={params.row.name}
              secondary={`${dateTimeFormatter(params.row.dateCreated)} - ${getUserName(params.row.owner)}`} />
          </RequestTooltip>
        )
    },
    { field: 'dateCreated', headerName: 'Created', flex: 10, renderCell:params => {
        return (
          <RequestTooltip request={params.row}>
            {dateTimeFormatter(params.value)}
          </RequestTooltip>
        );
      }
    },
    { field: 'name', headerName: 'Name', flex: 20 },
    { field: 'status', headerName: 'Status', flex: 10,minWidth: 100,
      renderCell:(params) => (
        <RequestTooltip request={params.row}>
          <RequestStatus request={params.row} />
        </RequestTooltip>
      )
    },
    { field: 'company', headerName: 'Company', flex: 10, valueFormatter:params => getCompanyName(params.value) },
    { field: 'companyGroup', headerName: 'Group', flex: 10 },
    { field: 'owner', headerName: 'Owner', flex: 10, valueFormatter:params => getUserName(params.value) },
    {
      field:'actionssmall',
      headerName:'Actions',
      type:'actions',
      flex: 10,
      minWidth: 160,
      renderCell:(params) =>
        (
          <RequestActions request={params.row} large={false} onEdit={() => onEdit(params.row)} />
        )
    },
    {
      field:'actionslarge',
      headerName:'Actions',
      type:'actions',
      flex: 10,
      minWidth: 350,
      renderCell:(params) =>
        (
          <RequestActions request={params.row} large={true} onEdit={() => onEdit(params.row)} />
        )
    }
  ];

  useEffect(() => {
    setColumnsVisible(isSmallScreen ? smallScreenColumns : largeScreenColumns);
  }, [isSmallScreen]);

  return (
    <>
      <Dialog open={uploadProgress > 0}>
        <DialogTitle>
          Upload in progress!
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please wait until upload completes. {uploadProgress} files remaining!
          </DialogContentText>
        </DialogContent>
      </Dialog>
      { detailsRequest && (
          <RequestDetail request={detailsRequest} users={users} onSave={onSave} onCancel={onCancel} />
      )}
      <Grid p={1} sx={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
        <UploadDropZone onFilesUploaded={onFilesUploaded} tooltip={"Upload one or more .xls or .xlsx estimate files to create a new request"} />
        {/*<Button variant={"contained"} size={"small"}><Upload />Upload new request</Button>*/}
        {auth.isAdmin() && (
            <Tooltip title={"Delete selected requests"} arrow>
              <Button variant={"contained"} size={"small"} onClick={() => onDelete(selectionModel)}><Delete />Delete</Button>
            </Tooltip>
        )}
      </Grid>
      <DataGrid
        loading={!requests}
        rows={rows}
        columns={columns}
        pageSize={100}
        rowsPerPageOptions={[25,50,100]}
        checkboxSelection
        components={{
          Toolbar: CustomToolbar,
        }}
        columnVisibilityModel={columnsVisible}
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
        }}
        selectionModel={selectionModel}
      />
    </>
  );
}

export default Requests;
