import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle, FormControl,
  Grid, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent,
  TextField,
  Typography
} from "@mui/material";
import {ApiClient, IEstimatingRequest, ITemplate, IUser} from "../../api/ApiClient";
import React, {useEffect, useState} from "react";

interface IRequestDetailProps {
  request: IEstimatingRequest | null,
  users?: IUser[],
  onSave: (request: IEstimatingRequest) => void,
  onCancel: () => void
}

export default function RequestDetail(props: IRequestDetailProps) {
  const {request, users, onSave, onCancel} = props;
  const [changedName, setChangedName] = useState<string | null>(null);
  const [changedOwner, setChangedOwner] = useState<string | null>(null);
  const [changedStatus, setChangedStatus] = useState<string | null>(null);
  const [saveStarted, setSaveStarted] = useState<boolean>(false);

  const currentName = (changedName === null ? request?.name : changedName) || "";
  const currentOwner = (changedOwner === null ? request?.owner : changedOwner) || "";
  const currentStatus = (changedStatus === null ? request?.status : changedStatus) || "";


  function onSaveClick() {
    if (!currentName)
    {
      alert("Name is required!");
      return;
    }
    if (!currentOwner)
    {
      alert("Owner is required!");
      return;
    }
    if (!currentStatus)
    {
      alert("Status is required!");
      return;
    }

    setSaveStarted(true);
    let newRequest = {...request} as IEstimatingRequest;
    newRequest.owner = currentOwner;
    newRequest.status = currentStatus;
    onSave(newRequest);
  }

  function onNameChange(event:  React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setChangedName(()=> event.target.value);
  }

  function handleChange(event:  SelectChangeEvent) {
    setChangedOwner(()=> event.target.value);
  }

  function handleStatusChange(event:  SelectChangeEvent) {
    setChangedStatus(()=> event.target.value);
  }

  return (
    <Dialog open={request != null}>
      <DialogTitle>
        Updating Template "{request!.name}"
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item p={1} xs={12} display={"flex"} flexDirection={"column"}>
            <TextField title={"Name"} placeholder={"Name"} onChange={onNameChange} value={currentName} disabled={true}/>
          </Grid>
          <Grid item p={1} xs={12} display={"flex"} flexDirection={"column"}>
            {!users ? (
                <Typography>Loading Users...</Typography>
            ) : (
                <FormControl>
                  <InputLabel id="owner-label">Owner</InputLabel>
                  <Select
                      labelId="owner-label"
                      id="owner-select"
                      value={currentOwner}
                      onChange={handleChange}
                      label="Owner"
                      required
                  >
                    <MenuItem disabled value="">
                      <em>Please Select</em>
                    </MenuItem>
                    {
                      users.map(template =>
                          <MenuItem key={template.id} value={template.id}>{template.name}</MenuItem>
                      )
                    }
                  </Select>
                </FormControl>
            )}
          </Grid>
          <Grid item p={1} xs={12} display={"flex"} flexDirection={"column"}>
            <FormControl>
              <InputLabel id="status-label">Status</InputLabel>
              <Select
                  labelId="status-label"
                  id="status-select"
                  value={currentStatus}
                  onChange={handleStatusChange}
                  label="Status"
                  required
              >
                <MenuItem key={"New"} value={"New"}>{"New"}</MenuItem>
                {request?.status === "Pending" && (
                    <MenuItem key={"Pending"} value={"Pending"}>{"Pending"}</MenuItem>
                )}
                {request?.status === "Ready" && (
                  <MenuItem key={"Ready"} value={"Ready"}>{"Ready"}</MenuItem>
                )}
                {request?.status === "Error" && (
                  <MenuItem key={"Error"} value={"Error"}>{"Error"}</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item p={1} xs={12} display={"flex"} justifyContent={"space-between"}>
            <Button variant={"contained"} onClick={() => onCancel()}>
              Cancel
            </Button>
            {!saveStarted && (
                <Button variant={"contained"} onClick={() => onSaveClick()}>
                  Save
                </Button>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
