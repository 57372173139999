import React from "react";
import {Route, Routes, Navigate } from "react-router-dom";
import {useAuthContext} from "../context/authContext";
import Layout from "../components/Layout";
import RequestsLoader from "../components/requests/RequestsLoader";
import CompaniesLoader from "../components/companies/CompaniesLoader";
import TemplatesLoader from "../components/templates/TemplatesLoader";
import Login from "../components/login/Login";
import UsersLoader from "../components/users/UsersLoader";

function NotFound() {
  return (<div>Not Found!</div>);
}

function AppRouter() {
  const auth = useAuthContext();

  return (
    <Layout>
      <Routes>
        {auth.isAuthenticated() && (
          <>
            <Route path="/" element={<Navigate to="/estimating/requests"  />} />
            <Route path="/estimating/" element={<Navigate to="/estimating/requests"  />} />
            <Route path="/estimating/requests" element={<RequestsLoader />}/>
            {auth.isAdmin() && (
                <>
                  <Route path="/admin/" element={<Navigate to="/admin/companies"  />} />
                  <Route path="/admin/companies" element={<CompaniesLoader />}/>
                  <Route path="/admin/templates" element={<TemplatesLoader />}/>
                  <Route path="/admin/users" element={<UsersLoader />}/>
                </>
            )}
            <Route path="*" element={<NotFound />} />
          </>
        )}
      </Routes>
    </Layout>
    );
}

export default AppRouter;
