import Requests from "./Requests";
import {useEffect, useState} from "react";
import {ApiClient, ICompany, IEstimatingRequest, IUser} from "../../api/ApiClient";
import {useInterval} from "usehooks-ts";

function RequestsLoader() {
  const [users, setUsers] = useState<IUser[] | undefined>(undefined);
  const [companies, setCompanies] = useState<ICompany[] | undefined>(undefined);
  const [requests, setRequests] = useState<IEstimatingRequest[] | undefined>(undefined);
  const [requestsVersion, setRequestsVersion] = useState<number>(0)

  function onRequestRefresh() {
    setRequestsVersion(prevNumber => prevNumber + 1);
  }

  useInterval(
      () => {
          onRequestRefresh();
      },
      60000
  )

  useEffect(()=> {
      const apiClient = new ApiClient();

      apiClient.getUsers().then(users => {
        setUsers(users);
      }).catch(reason => {
        alert('Error loading users: ' + reason);
      })
    },
    []
  )

  useEffect(()=> {
      const apiClient = new ApiClient();

      apiClient.getCompanies().then(companies => {
        setCompanies(companies);
      }).catch(reason => {
        alert('Error loading companies: ' + reason);
      })
    },
  []
  )


  useEffect(()=> {
      const apiClient = new ApiClient();

      apiClient.getRequests().then(requests => {
        requests.sort((a,b) => {
            return new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime();
        });
        setRequests(requests);
      }).catch(reason => {
        alert('Error loading requests: ' + reason);
      })
    },
    [users && companies && requestsVersion]
  )

  return (
    <>
      <Requests users={users} companies={companies} requests={requests} onRequestRefresh={onRequestRefresh} />
    </>
  )
}

export default RequestsLoader;
