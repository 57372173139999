import React from "react";
import {Box, Tooltip, Typography} from "@mui/material";
import {dateTimeFormatter} from "../../util/DateUtil";
import {ITemplate} from "../../api/ApiClient";

interface ITemplateTooltipProps {
  template:ITemplate;
}

export function TemplateTooltip(props: React.PropsWithChildren<ITemplateTooltipProps>) {
  const {template, children} = props;

  return (
    <Tooltip title={
      <Box>
        <Typography>{template.name}</Typography>
        <div>{`Created: ${dateTimeFormatter(template.dateCreated)}`}</div>
      </Box>
    } arrow>
      <Box>
        {children}
      </Box>
    </Tooltip>
  );
}
