import {useEffect, useState} from "react";
import {ApiClient, ITemplate} from "../../api/ApiClient";
import Templates from "./Templates";

function TemplatesLoader() {
  const [templates, setTemplates] = useState<ITemplate[] | undefined>(undefined);
  const [templatesVersion, setTemplatesVersion] = useState<number>(0)

  function onTemplateRefresh() {
    setTemplatesVersion(prevNumber => prevNumber + 1);
  }

  useEffect(()=> {
      const apiClient = new ApiClient();
      apiClient.getTemplates().then(templates => {
        templates.sort((a,b) => {
            let result = a.type.localeCompare(b.type);
            if (result === 0) {
                result = new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime();
            }

            return result;
        });
        setTemplates(templates);
      }).catch(reason => {
        alert('Error loading templates: ' + reason);
      })
    },
    [templatesVersion]
  )

  return (
    <Templates templates={templates} onTemplateRefresh={onTemplateRefresh} />
  )
}

export default TemplatesLoader;
