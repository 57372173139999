import React from "react";
import {Box, Tooltip, Typography} from "@mui/material";
import {dateTimeFormatter} from "../../util/DateUtil";
import {IUser} from "../../api/ApiClient";

interface IResultTooltipProps {
  user:IUser;
}

export function UserTooltip(props: React.PropsWithChildren<IResultTooltipProps>) {
  const {user, children} = props;

  return (
    <Tooltip title={
      <Box>
        <Typography>{user.name}</Typography>
        <div>{`Email: ${user.email}`}</div>
        <div>{`Company: ${user.company}`}</div>
        <div>{`Group: ${user.group}`}</div>
        <div>{`Role: ${user.accessLevel}`}</div>
      </Box>
    } arrow>
      <Box>
        {children}
      </Box>
    </Tooltip>
  );
}
