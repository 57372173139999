import React, {useEffect, useState} from "react";
import {DataGrid, GridColDef, GridSelectionModel} from '@mui/x-data-grid';
import TemplateActions from "./TemplateActions";
import UploadDropZone from "../UploadDropZone";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid, Tooltip,
  useMediaQuery,
  useTheme
} from "@mui/material";
import {Delete} from "@mui/icons-material";
import {CustomToolbar} from "../CustomToolbar";
import MobileRow from "../MobileRow";
import {ApiClient, ICompany, ITemplate} from "../../api/ApiClient";
import {dateTimeFormatter} from "../../util/DateUtil";
import {TemplateTooltip} from "./TemplateTooltip";
import CompanyDetail from "../companies/CompanyDetail";
import TemplateDetail from "./TemplateDetail";
import {RequestTooltip} from "../requests/RequestTooltip";

const largeScreenColumns = {
  status: true,
  name:true,
  owner:true,
  mobilerow:false,
  actionssmall:false,
  actionslarge:true,
  dateCreated: true,
  datePending: true,
  dateReady: true,
}

const smallScreenColumns = {
  status: true,
  name:false,
  owner:false,
  mobilerow:true,
  actionssmall:true,
  actionslarge:false,
  dateCreated: false,
  datePending: false,
  dateReady: false,
}

interface ITemplatesProps {
  templates?:ITemplate[];
  onTemplateRefresh:() => void;
}

function Templates(props : ITemplatesProps) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [columnsVisible, setColumnsVisible] = useState(largeScreenColumns);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [detailsTemplate, setDetailsTemplate] = useState<ITemplate | null>(null);
  const { templates, onTemplateRefresh } = props;
  const rows = templates ? templates : [];

  function onFilesUploaded(files :File[]) {
    if (files.length > 0) {
      setUploadProgress(files.length);
      for(const file of files) {
        const apiClient = new ApiClient();
        apiClient.createNewTemplate(file).catch(reason => {
          alert('Upload failed!');
        }).finally(() => {
          onTemplateRefresh();
          setUploadProgress(prevState => prevState - 1);
        });
      }
    } else {
      alert('No acceptable files found! Please upload an excel file (xls,xlsx)!');
    }
  }

  function onSave(template: ITemplate) {
    const apiClient = new ApiClient();

    if (template.id) {
      apiClient.updateTemplate(template.id, template).catch( () => {
        alert('Failed to update the template!');
      }).finally(() => {
        setDetailsTemplate(null);
        onTemplateRefresh();
      });
    }
  }

  function onCancel() {
    setDetailsTemplate(null);
  }

  function onEdit(template: ITemplate) {
    setDetailsTemplate(template);
  }

  function onDelete(selectionModel: GridSelectionModel) {
    if (!window.confirm(`Do you want to delete ${selectionModel.length} templates?`))
    {
      return;
    }

    const templateIdsToRemove = [];
    for (const index in selectionModel)
    {
      const rowId = selectionModel[index];
      var template = templates?.find(template => template.id === rowId.toString());
      if (template && template.name === "Results Template") {
        alert("Results template cannot be removed!");
        return;
      }
      else if (template) {
        templateIdsToRemove.push(rowId);
      }
    }

    if (templateIdsToRemove.length > 0)
    {
      const apiClient = new ApiClient();

      const deleteTemplates = templateIdsToRemove.map( rowId => {
        return apiClient.deleteTemplate(rowId.toString());
      })

      Promise.all(deleteTemplates).catch( (error) => {
        alert('One or more deletes failed! ' + error);
      }).finally(() => {
        onTemplateRefresh();
      });
    }
    else
    {
      alert("Could not find any templates to remove!");
    }
  }


  const columns: GridColDef[] = [
    {
      field:'mobilerow',
      headerName:'Info',
      flex: 90,
      renderCell:(params) =>
        (
          <TemplateTooltip template={params.row}>
            <MobileRow
              primary={params.row.name}
              secondary={`${dateTimeFormatter(params.row.dateCreated)}`} />
          </TemplateTooltip>
        )
    },
    { field: 'dateCreated', headerName: 'Created', flex: 10, renderCell:params => {
        return (
          <TemplateTooltip template={params.row}>
            {dateTimeFormatter(params.value)}
          </TemplateTooltip>
        );
      }
    },
    { field: 'name', headerName: 'Name', flex: 20 },
    { field: 'type', headerName: 'Type', flex: 20 },
    {
      field:'actionssmall',
      headerName:'Actions',
      type:'actions',
      flex: 10,
      minWidth: 120,
      renderCell:(params) =>
        (
          <TemplateTooltip template={params.row}>
            <TemplateActions template={params.row} large={false} onEdit={() => onEdit(params.row)}/>
          </TemplateTooltip>
        )
    },
    {
      field:'actionslarge',
      headerName:'Actions',
      type:'actions',
      flex: 10,
      minWidth: 120,
      renderCell:(params) =>
        (
          <TemplateTooltip template={params.row}>
            <TemplateActions template={params.row} large={true} onEdit={() => onEdit(params.row)} />
          </TemplateTooltip>
        )
    }
  ];

  useEffect(() => {
    setColumnsVisible(isSmallScreen ? smallScreenColumns : largeScreenColumns);
  }, [isSmallScreen]);

  return (
    <>
      <Dialog open={uploadProgress > 0}>
        <DialogTitle>
          Upload in progress!
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please wait until upload completes. {uploadProgress} files remaining!
          </DialogContentText>
        </DialogContent>
      </Dialog>
      { detailsTemplate && (
        <TemplateDetail template={detailsTemplate} onSave={onSave} onCancel={onCancel} />
      )}
      <Grid p={1} sx={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
        <UploadDropZone onFilesUploaded={onFilesUploaded}  tooltip={"Upload one or more .xls, .xlsx or .xlsm template files"} />
        {/*<Button variant={"contained"} size={"small"}><Upload />Upload new template</Button>*/}
        <Tooltip title={"Delete selected requests"} arrow>
          <Button variant={"contained"} size={"small"} onClick={() => onDelete(selectionModel)}><Delete />Delete</Button>
        </Tooltip>
      </Grid>
      <DataGrid
        loading={!templates}
        rows={rows}
        columns={columns}
        pageSize={100}
        rowsPerPageOptions={[25,50,100]}
        checkboxSelection
        components={{
          Toolbar: CustomToolbar,
        }}
        columnVisibilityModel={columnsVisible}
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
        }}
        selectionModel={selectionModel}
        getRowId={template => template.id}
      />
    </>
  );
}

export default Templates;

