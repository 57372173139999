import {Box, Button, Fab, Link, Tooltip} from "@mui/material";
import { IEstimatingRequest } from "../../api/ApiClient";
import styles from './CompaniesActions.module.css'
import {AttachFile, CloudDone, Download, Edit, FileDownload, Send} from "@mui/icons-material";

interface IRequestActionProps {
  company: IEstimatingRequest;
  large: boolean;
  onEdit: () => void;
}

function CompaniesActions(props: IRequestActionProps) {
  const {company, large, onEdit} = props;

  return (
    <Box className={styles.container}>
      <Tooltip title={"Edit Company"} arrow>
        <Box>
        {!large &&
          (
            <Fab color="primary" size={"small"} aria-label="go" sx={{
              ":hover":{
                backgroundColor:"primary.light"
              }
            }} onClick={onEdit}>
              <Edit />
            </Fab>
          )}
        {large &&
          (
            <Button color="primary" variant={"contained"} size={"small"} aria-label="go" sx={{
              ":hover":{
                backgroundColor:"primary.light"
              }
            }} onClick={onEdit}>
              <Edit />
              Edit
            </Button>
          )}
        </Box>
      </Tooltip>
    </Box>
  );
}

export default CompaniesActions
