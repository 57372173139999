import React, {useEffect, useState} from "react";
import {DataGrid, GridColDef, GridSelectionModel} from '@mui/x-data-grid';
import UsersActions from "./UsersActions";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid, Tooltip,
  useMediaQuery,
  useTheme
} from "@mui/material";
import {Create, Delete} from "@mui/icons-material";
import {CustomToolbar} from "../CustomToolbar";
import MobileRow from "../MobileRow";
import {ApiClient, ICompany, ITemplate, IUser} from "../../api/ApiClient";
import {dateTimeFormatter} from "../../util/DateUtil";
import {UserTooltip} from "./UserTooltip";
import UserDetail from "./UserDetail";
import {RequestTooltip} from "../requests/RequestTooltip";

const largeScreenColumns = {
  name:true,
  company:true,
  group: true,
  accessLevel: true,
  mobilerow:false,
  actionssmall:false,
  actionslarge:true,
}

const smallScreenColumns = {
  name:false,
  company:false,
  group: false,
  accessLevel: false,
  mobilerow:true,
  actionssmall:true,
  actionslarge:false,
}

interface IUsersProps {
  companies:ICompany[];
  users?:IUser[];
  onUserRefresh:() => void;
}


function Users(props : IUsersProps) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [columnsVisible, setColumnsVisible] = useState(largeScreenColumns);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [detailsUser, setDetailsUser] = useState<IUser | null>(null);
  const { companies, users, onUserRefresh } = props;
  const rows = users ? users : [];

  const companiesById = Object.fromEntries(companies.map(company => [company.id, company.name]));

  function onSave(user: IUser) {
    const apiClient = new ApiClient();

    if (user.id) {
      apiClient.updateUser(user).catch( () => {
        alert('Failed to update the user!');
      }).finally(() => {
        setDetailsUser(null);
        onUserRefresh();
      });
    } else {
      apiClient.createNewUser(user).catch( () => {
        alert('Failed to create the user!');
      }).finally(() => {
        setDetailsUser(null);
        onUserRefresh();
      });
    }
  }

  function onCancel() {
    setDetailsUser(null);
  }

  function onCreate() {
    const user = {} as IUser;
    user.accessLevel = "User";
    user.group = "All";
    setDetailsUser(user);
  }

  function onEdit(user: IUser) {
    setDetailsUser(user);
  }

  function onDelete(selectionModel: GridSelectionModel) {
    if (!window.confirm(`Do you want to delete ${selectionModel.length} users?`))
    {
      return;
    }

    const apiClient = new ApiClient();

    const deleteUsers = selectionModel.map( rowId => {
      return apiClient.deleteUser(rowId.toString());
    })

    Promise.all(deleteUsers).catch( (error) => {
      alert('One or more users failed!');
    }).finally(() => {
      onUserRefresh();
    });
  }

  function getCompany(id: string) {
    return companiesById[id];
  }

  const columns: GridColDef[] = [
    {
      field:'mobilerow',
      headerName:'Info',
      flex: 90,
      renderCell:(params) =>
        (
          <UserTooltip user={params.row}>
            <MobileRow
              primary={params.row.name}
              secondary={`${params.row.company}`} />
          </UserTooltip>
        )
    },
    { field: 'name', headerName: 'Name', flex: 20 },
    { field: 'company', headerName: 'Company', flex: 20, valueFormatter:params => getCompany(params.value) },
    { field: 'group', headerName: 'Group', flex: 10 },
    { field: 'accessLevel', headerName: 'Access Level', flex: 10 },
    {
      field:'actionssmall',
      headerName:'Actions',
      type:'actions',
      flex: 10,
      minWidth: 120,
      renderCell:(params) =>
        (
          <UserTooltip user={params.row}>
            <UsersActions user={params.row} large={false} onEdit={() => onEdit(params.row)} />
          </UserTooltip>
        )
    },
    {
      field:'actionslarge',
      headerName:'Actions',
      type:'actions',
      flex: 10,
      minWidth: 120,
      renderCell:(params) =>
        (
          <UserTooltip user={params.row}>
            <UsersActions user={params.row} large={true} onEdit={() => onEdit(params.row)} />
          </UserTooltip>
        )
    }
  ];

  useEffect(() => {
    setColumnsVisible(isSmallScreen ? smallScreenColumns : largeScreenColumns);
  }, [isSmallScreen]);

  return (
    <>
      { detailsUser && (
        <UserDetail companies={companies} user={detailsUser} onSave={onSave} onCancel={onCancel} />
      )}
      <Grid p={1} sx={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
        <Tooltip title={"Create a new user"} arrow>
          <Button variant={"contained"} size={"small"} onClick={() => onCreate()}><Create />New User</Button>
        </Tooltip>
        <Tooltip title={"Delete selected user"} arrow>
          <Button variant={"contained"} size={"small"} onClick={() => onDelete(selectionModel)}><Delete />Delete</Button>
        </Tooltip>
      </Grid>
      <DataGrid
        loading={!users}
        rows={rows}
        columns={columns}
        pageSize={100}
        rowsPerPageOptions={[25,50,100]}
        checkboxSelection
        components={{
          Toolbar: CustomToolbar,
        }}
        columnVisibilityModel={columnsVisible}
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
        }}
        selectionModel={selectionModel}
        getRowId={user => user.id}
      />
    </>
  );
}

export default Users;

